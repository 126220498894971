import type { ActionFunction, LoaderFunction } from 'react-router';
import { redirect, Form, useLoaderData } from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';
import { ClientOnly } from 'remix-utils/client-only';
import { auth, sessionStorage } from '~/auth.server';
import api from '~/utils/api';

const RECAPTCHA_KEY = '6LcqF6gZAAAAAHE-lzA_9GAux7eX9OHaQ5VdEo0C';

type LoaderData = {
  error: { message: string } | null;
};

type ActionData = {
  error?: { message: string };
};

export const action: ActionFunction = async ({ request }) => {
  const formBody = await request.formData();
  const password = formBody.get('password');
  const email = formBody.get('email');

  if (password) {
    return redirect(`/login/password?email=${email}`);
  }

  const { data } = await api('userAuthEmailLink', {
    emailAddress: formBody.get('email'),
    recaptcha: formBody.get('g-recaptcha-response'),
  });

  if (data === 'OK') {
    return redirect('/login/code/email');
  } else {
    const error = await data;
    return { error } as ActionData;
  }
};

export const loader: LoaderFunction = async ({ request }) => {
  await auth.isAuthenticated(request, {
    successRedirect: '/dashboard/projects',
  });
  const session = await sessionStorage.getSession(
    request.headers.get('Cookie'),
  );
  const error = session.get(auth.sessionErrorKey) as LoaderData['error'];

  return { error } as LoaderData;
};

export default function LoginPage() {
  const { error } = useLoaderData<LoaderData>();

  return (
    <>
      {error ? <div>{error.message}</div> : null}
      <Form method="post">
        <label htmlFor="email" className="label">
          <span>Email</span>
          <input
            className="input"
            type="email"
            name="email"
            id="email"
            defaultValue=""
          />
        </label>
        <br />
        <ClientOnly>
          {() => <ReCAPTCHA size="normal" sitekey={RECAPTCHA_KEY} />}
        </ClientOnly>
        <br />
        <div className="stack">
          <button name="magic-link" value="true" className="button">
            Send Magic Link
          </button>
          <p>Or</p>
          <button name="password" value="true" className="button secondary">
            Log in with password
          </button>
        </div>
      </Form>
    </>
  );
}
